<template>
  <section class="h-100">

    <div style="padding: 40px;">
      <div class="m-auto" style="display: inline-block;">
        <img
          src="@/assets/images/relatus_green_logo_horizontal.svg"
        >
      </div>

      <div style="display: inline-block;float: right;margin-top: 13px;">
        <span class="uppercase">Pre-register</span>
        <img
          src="@/assets/images/menu.svg"
          style="display: inline-block;margin-left: 20px;"
        >
      </div>
    </div>

    <div class="flex h-screen"  style="height:80vh;">
      <div class="m-auto">

        <img
          src="@/assets/images/relatus_green_logo.svg"
          class="m-auto"
          style="height: 100px;"
        >

        <div style="margin-top: 30px;">
          <h3 class="text text-center">
            Payment Complete!
          </h3>

          <p class="text text-center mt-3">
            Thank you so much for your purchase. <br/> An email will arrive in your inbox shortly with your receipt.
          </p>

          <img
            src="@/assets/images/payment_steps_completed.svg"
            class="mt-5"
            style="width: 100%"
          >

          <img
            src="@/assets/images/payment_summary.svg"
            class="mt-5"
            style="width: 100%"
          >
        </div>

        <div class="mt-7 text-center">
          <router-link
            :to="{name: 'registration.plans'}"
            class="btn btn-white align-self-center me-auto mt-8 mr-3"
            style="color: #83C03B!important;border-color: #83C03B!important;"
          >
            Cancel
          </router-link>

          <router-link
            :to="{name: 'setup.options'}"
            class="btn btn-primary align-self-center me-auto mt-8 mr-3"
            style="background-color: #83C03B!important;border-color: #83C03B!important;"
          >
            Submit
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'PaymentSuccess',
}
</script>

<style scoped>
h3 {
  font-family: 'Helvetica';
  font-size: 30px;
  line-height: 40px;
  color: #1E1E1E;
  letter-spacing: 1px;
  font-weight: 700;
}
.sign-up p {
  font-family: 'Helvetica';
  font-size: 24px;
  line-height: 30px;
  color: #1E1E1E;
  margin-bottom: 0;
  font-weight: 300;
}
.sign-up .social-links {
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  border-radius: 45px;
  padding: 10px;
  min-height: 50px;
}
.sign-up .social-links img {
  height: 24px;
}

/* FIX: Input must be a component */
.btn-primary {
  background-color: #FF6600;
  color: #FFFFFF;
  padding: 10px;
  border: 1px solid #FF6600;
  box-sizing: border-box;
  border-radius: 35px;
}
.alert-error {
  background: rgba(217, 54, 31, 0.3);
  border-radius: 10px;
  padding: 10px;
}
.alert-error p {
  font-size: 14px;
}
a.btn-white {
  background-color: transparent;
  color: #FFFFFF;
  padding: 10px;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 35px;
}
</style>
